import Vue from "vue";
import App from "./App.vue";
import vueCustomElement from "vue-custom-element";
//import "document-register-element/build/document-register-element";
import "./betwayBridge";

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";

Vue.use(VueAwesomeSwiper);
Vue.use(vueCustomElement);

Vue.config.productionTip = false;

Vue.customElement("betway-banner", App);
