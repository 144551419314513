/* 
    BRIAN KHUELE

    Use this file as a guideline to implement betway bridge listeners in your environment.
    Some these listeners will be generic and some will be specific to your environment.
   
    --Casing must be taken into account for the event listener names.
*/
addEventListener("NAVIGATETO", (message) => {
  //window.location.href = window.location.origin + message.CTAValue;
  const betwayRowInstance = window.betwayRowInstance;
  const betwaySynapseInstance = window.betwaySynapseInstance;

  if (betwayRowInstance != "undefined" && betwayRowInstance != null) {
    betwayRowInstance.zone.run(() => {
      betwayRowInstance.navigateToURL(message.CTAValue);
    });
    return;
  }

  if (betwaySynapseInstance) {
    betwaySynapseInstance.navigateToURL(message.CTAValue);
    return;
  }
});

addEventListener("NAVIGATENEWTAB", (message) => {
  window.open(message.CTAValue);
});

addEventListener("LAUNCH", (message) => {
  const betwayRowInstance = window.betwayRowInstance;
  const betwaySynapseInstance = window.betwaySynapseInstance;
  switch (message.CTAValue) {
    case "Chat":
      if (betwayRowInstance) {
        betwayRowInstance.zone.run(() => {
          betwayRowInstance.showChatDialog();
        });
      }
      if (betwaySynapseInstance) {
        betwaySynapseInstance.showChatDialog();
      }
      break;
    case "Registration":
      if (betwayRowInstance) {
        betwayRowInstance.zone.run(() => {
          betwayRowInstance.showRegistrationDialog();
        });
      }
      if (betwaySynapseInstance) {
        betwaySynapseInstance.showRegistrationDialog();
      }
      break;
    case "Login":
      if (betwayRowInstance) {
        betwayRowInstance.zone.run(() => {
          betwayRowInstance.showLoginDialog();
        });
      }
      if (betwaySynapseInstance) {
        betwaySynapseInstance.showLoginDialog();
      }
      break;

    case "Deposit":
      if (betwayRowInstance) {
        betwayRowInstance.zone.run(() => {
          betwayRowInstance.showDepositDialog();
        });
      }
      
      if (betwaySynapseInstance) {
        betwaySynapseInstance.showDepositDialog();
      }
      break;
    case "Withdrawals ":
      if (betwayRowInstance) {
        betwayRowInstance.zone.run(() => {
          betwayRowInstance.showWithdrawalsDialog();
        });
      }
      if (betwaySynapseInstance) {
        betwaySynapseInstance.showWithdrawalsDialog();
      }

      break;
    case "Inbox ":
      if (betwayRowInstance) {
        betwayRowInstance.zone.run(() => {
          betwayRowInstance.showInboxDialog();
        });
      }
      if (betwaySynapseInstance) {
        betwaySynapseInstance.showInboxDialog();
      }
      break;
    default:
      break;
  }
});

addEventListener("ACCOUNT", (message) => {
  //const betwayRowInstance = window.betwayRowInstance;
  //const betwaySynapseInstance = window.betwayRowInstance;
  //This event listener controls and triggers account sections depending on the CTA value passed through
  switch (message.CTAValue) {
    case "":
      break;

    default:
      break;
  }
  //this.toggleAccountModal({ shouldShow: true })
});

addEventListener("MOREINFO", (message) => {
  //This event listener must trigger a platform specific dialog and display the moreInfo value in the message
  //If the env is betway ROW
  const betwayRowInstance = window.betwayRowInstance;
  const betwaySynapseInstance = window.betwaySynapseInstance;

  if (betwayRowInstance != "undefined" && betwayRowInstance != null) {
    betwayRowInstance.zone.run(() => {
      betwayRowInstance.showMoreInfoDialog(message.moreInfo);
    });
    return;
  }

  if (betwaySynapseInstance != "undefined" && betwaySynapseInstance != null) {
      betwaySynapseInstance.showMoreInfoDialog(message.moreInfo);
    return;
  }

  //fall to default alert dialog??? Will need to build a default dialog here, gonna try without install 3rd party ilbrary/component to keep bundle size small
  alert(message.moreInfo);
});
