<template>
  <div class="BetwayCarousel">
    <div class="bwcContainer" @mouseenter="showNavs" @mouseleave="hideNavs">
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        @slideChangeTransitionStart="slideChangeTransitionStart"
        @click="handleClickSlide($event)"
      >
        <template v-for="(banner, index) in bannerData">
          <swiper-slide :key="index + Math.random()">
            <img
              :src="
                `${
                  getScreenSize() <= 640
                    ? banner.MobileImage
                    : banner.DesktopImage
                }`
              "
              :key="index + Math.random()"
              :alt="index"
              @error="bannerImageLoadError(index)"
            />
            <div class="textSection" v-html="banner.textSection"></div>
            <div class="moreInfo" v-if="banner.MoreInfo.trim().length">
              <button>
                <i class="moreInfoIcon"></i>
              </button>
            </div>
          </swiper-slide>
        </template>
      </swiper>
      <div
        v-show="navigation == true && getScreenSize() > 640"
        id="swiper-button-prev"
        class="swiper-button-prev"
        slot="PrevButton"
      ></div>
      <div
        v-show="navigation == true && getScreenSize() > 640"
        id="swiper-button-next"
        class="swiper-button-next"
        slot="NextButton"
      ></div>
    </div>
    <div
      v-show="pagination == true"
      class="swiper-pagination"
      slot="pagination"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Banner2",
  props: [
    "interval",
    "slide-count", //remember that camel cased props should be kebab cased here ;)
    "pagination",
    "navigation",
    "region",
    "vertical",
    "channel",
    "auth",
    "culture",
  ],
  data: (instance) => {
    return {
      bannerData: [],
      rootApiURL: "https://rowapi.gmgamingsystems.com",
      kenticoContentURL: "https://banners.gmgamingsystems.com",
      swiperOptions: {
        autoplay: { delay: instance.interval * 1000 },
        loop: true,
        slidesPerView: instance.slideCount,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: instance.slideCount * 2,
        },
        initialSlide: 0,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          280: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
     
          640: {
            slidesPerView: instance.slideCount,
            spaceBetween: 30,
          },
        },
        effect: "slide",
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    bannerImageLoadError(i) {
      if (this.getScreenSize() <= 640) {
        this.bannerData[i].MobileImage =
          "https://banners.betwayafrica.com/medialibraries/banners.betwayafrica.com/HTML5Banners/Default-Banner-Mobile1.png";
      } else {
        this.bannerData[i].DesktopImage =
          "https://banners.betwayafrica.com/medialibraries/banners.betwayafrica.com/HTML5Banners/Default-Banner-Desktop.png";
      }
    },
    hideNavs: function() {
      document.getElementById("swiper-button-prev").style.visibility = "hidden";
      document.getElementById("swiper-button-next").style.visibility = "hidden";
    },
    showNavs: function() {
      document.getElementById("swiper-button-prev").style.visibility =
        "visible";
      document.getElementById("swiper-button-next").style.visibility =
        "visible";
    },
    getScreenSize: function() {
      return window.screen.width;
    },
    handleClickSlide: function(event) {
      if (
        event.target.localName !== "button" &&
        event.target.localName !== "i"
      ) {
        const index = this.swiper.clickedSlide.getAttribute(
          "data-swiper-slide-index"
        );
        let CTA = this.bannerData[index].CTA; //TODO: Come back and check this index. Something is fishy here...
        if (String(CTA).trim().length === 0) return;
        CTA = String(CTA).replace("<br>", "");
        CTA = String(CTA).replace("&nbsp;", "");

        //Check loggedInState to determine which CTA to call
        let Action;
        let Value;
        const JSON_CTA = JSON.parse(CTA);

        if (this.auth) {
          if (Object.prototype.hasOwnProperty.call(JSON_CTA, "loggedIn")) {
            Action = JSON_CTA.loggedIn["Action"];
            Value = JSON_CTA.loggedIn["Value"];
          }
        } else {
          if (Object.prototype.hasOwnProperty.call(JSON_CTA, "loggedOut")) {
            Action = JSON_CTA.loggedOut["Action"];
            Value = JSON_CTA.loggedOut["Value"];
          }
        }

        //get clicked banner using index of clicked slide and extract the CTA
        if (Action) {
          const e = new Event(String(Action).toUpperCase());
          e.CTAValue = Value;
          dispatchEvent(e);
        }
      } else if (
        event.target.localName === "button" ||
        event.target.localName === "i"
      ) {
        if (this.swiper.isBeginning) {
          this.moreInfo();
        }
      }
    },
    moreInfo() {
      const index = this.swiper.clickedSlide.getAttribute(
        "data-swiper-slide-index"
      );
      const moreInfo = this.bannerData[index].MoreInfo;
      const e = new Event("MOREINFO");
      e.moreInfo = moreInfo;
      dispatchEvent(e);
    },
    slideChangeTransitionStart() {
      let $wrapperEl = this.swiper.$wrapperEl;
      let vm = this; //This is the vue instance

      $wrapperEl.children(0).each(function() {
        /**VERY IMPORTANT: Since DOM elements were manually copied above, 
        moreInfo buttons on the slides need to be manually assigned an onclick event binded the main vue instance **/
        //console.log(vm.swiper.slides)
        const moreInfoButton =
          typeof this.children[2] != "undefined" && this.children[2] != null
            ? this.children[2].children[0]
            : null;
        if (moreInfoButton) {
          moreInfoButton.onclick = vm.moreInfo;
        }
      });
    },
  },
  mounted() {
    //Extract Attributes. Using props has proven to be an issue when intergrating with other frameworks
    const region = this.$el.parentElement.getAttribute("region");
    const vertical = this.$el.parentElement.getAttribute("vertical");
    const channel = this.$el.parentElement.getAttribute("channel");
    const auth = this.$el.parentElement.getAttribute("auth");
    const culture = this.$el.parentElement.getAttribute("culture");
    let url =
      window.betwaySynapseInstance &&
      window.betwaySynapseInstance.isBetwayAfrica
        ? `https://api.betwayafrica.com/All/Active?regionCode=${region}&vertical=${vertical}&channelCode=${channel}&isLoggedInBanner=${auth}&api-version=1`
        : `https://rowapi.gmgamingsystems.com/api/v1/Banners/${region}/${vertical}/${channel}?authenticated=${auth}`;
    //get banner data from API
    fetch(url)
      .then(async (response) => {
        const data = await response.json();
        data.map(async (banner) => {
          const kenticoUrl = "https://banners.gmgamingsystems.com";
          let result = await fetch(
            `${kenticoUrl}${banner.url.toLowerCase()}?lang=${culture}`
          );
          const data = await result.text();
          const parser = new DOMParser();
          const document = parser.parseFromString(data, "text/html");
          const textSection =
            document.getElementsByTagName("BannerText")[0] != null
              ? document.getElementsByTagName("BannerText")[0].innerHTML
              : "";
          const DesktopImage =
            kenticoUrl +
            document
              .getElementsByTagName("DesktopImage")[0]
              .children[0].getAttribute("src");

          const MobileImage =
            kenticoUrl + document.getElementsByTagName("MobileImage")[0].children[0].getAttribute('src')
          const MoreInfo = document.getElementsByTagName("MoreInfo")[0]
            .innerHTML;
          const CTA = document.getElementsByTagName("CTA")[0].innerHTML;

          if (String(data).includes("TextOnlyBanner")) {
            this.bannerData.push({
              bannerType: "text",
              textSection,
              MobileImage,
              DesktopImage,
              MoreInfo,
              CTA,
            });
          }

          if (String(data).includes("ImageOnlyBanner")) {
            this.bannerData.push({
              bannerType: "image",
              textSection,
              DesktopImage,
              MobileImage,
              MoreInfo,
              CTA,
            });
          }
        });
      })
      .catch((reason) => {
        console.log(reason);
      });
  },
};
</script>
<style lang="scss">
.BetwayCarousel {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow: hidden;
  .bwcContainer {
    width: 100%;
    height: 100%;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 100% !important;
      height: 130px !important;
      object-fit: contain !important;
      object-position: center !important;
    }

    .textSection {
      position: absolute;
      width: 50%;
      height: 80%;
      left: 0;
      top: 0;
      text-align: left;
      font-family: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      > * {
        margin: 0 0 0 1rem;
      }
    }

    .moreInfo {
      position: absolute;
      background: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      left: 48%;
      bottom: 11%;
      text-align: center;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: 0;

      button {
        height: 100%;
        width: 100%;
        border: none;
        cursor: pointer;
        margin: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: unset;

        i {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .swiper-slide {
      width: 100% !important;
      height: 100% !important;

      img {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .moreInfo {
      width: 20px !important;
      height: 20px !important;
    }

    .textSection {
      > * {
        margin: 0 0 0 0.5rem !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-slide {
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
      }
    }
  }

  .swiper-pagination {
    position: unset !important;
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    margin-top: 3px;
  }

  .swiper-pagination-bullet {
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 0 10px !important;
    width: 8px !important;
    height: 4px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #00a826 !important;
  }

  .moreInfoIcon {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+PHN2ZyBmaWxsPSIjODA4MDgwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiPiAgICA8cGF0aCBkPSJNIDEyIDIgQyA2LjQ4ODk5NzEgMiAyIDYuNDg4OTk3MSAyIDEyIEMgMiAxNy41MTEwMDMgNi40ODg5OTcxIDIyIDEyIDIyIEMgMTcuNTExMDAzIDIyIDIyIDE3LjUxMTAwMyAyMiAxMiBDIDIyIDYuNDg4OTk3MSAxNy41MTEwMDMgMiAxMiAyIHogTSAxMiA0IEMgMTYuNDMwMTIzIDQgMjAgNy41Njk4Nzc0IDIwIDEyIEMgMjAgMTYuNDMwMTIzIDE2LjQzMDEyMyAyMCAxMiAyMCBDIDcuNTY5ODc3NCAyMCA0IDE2LjQzMDEyMyA0IDEyIEMgNCA3LjU2OTg3NzQgNy41Njk4Nzc0IDQgMTIgNCB6IE0gMTEgNyBMIDExIDkgTCAxMyA5IEwgMTMgNyBMIDExIDcgeiBNIDExIDExIEwgMTEgMTcgTCAxMyAxNyBMIDEzIDExIEwgMTEgMTEgeiIvPjwvc3ZnPg==");
    // background-image: url("/information.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: block;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 2em !important;
    height: 2em !important;
    top: 50% !important;
    background: rgba(77, 77, 77, 0.3);
    z-index: 9999 !important;
    border-radius: 50%;
    visibility: hidden;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 1rem !important;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 0 !important;
    color: white;
  }
}
</style>
